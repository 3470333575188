import React from 'react';

import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { Margin } from '../Common/styled/Margins';
import { Spacer } from '../Common/styled/Spacer';
import { Row, Column } from '../Common/styled/Groups';
import { GreyLabel } from '../Common/styled/GreyLabel';
import { Span } from '../Common/styled/Text';
import { BoldTextButton } from '../Common/styled/TextButton';

export const UserProfile = ({ user }) => {
  const { t } = useTranslation();
  const isHealthProfessional = (user.professionalRole === 'HEALTH_PROFESSIONAL');
  return (
    <div>
      <Margin mx-0>
        <Row>
          <Column>
            <GreyLabel>{t('name')}</GreyLabel>
            <Span>{`${user.name} ${user.surname}`}</Span>
          </Column>
          <Spacer emptySibling />
          <Column />
          <Link to="/user/edit">
            <BoldTextButton>{t('edit')}</BoldTextButton>
          </Link>
        </Row>
      </Margin>
      <Margin mx-0>
        <Row>
          <Column>
            <GreyLabel>{t('email')}</GreyLabel>
            <Span>{user.email}</Span>
          </Column>
          <Spacer emptySibling />
          <Column />
        </Row>
      </Margin>
      <Margin mx-0>
        <Row>
          <Column>
            <GreyLabel>{t('personal.Id')}</GreyLabel>
            <Span>{user.personalId}</Span>
          </Column>
          <Spacer emptySibling />
          <Column />
        </Row>
      </Margin>
      <Margin mx-0>
        <Row>
          <Column>
            <GreyLabel>{t('professional.role')}</GreyLabel>
            <Span>{t(user.professionalRole.toLowerCase())}</Span>
          </Column>
          <Spacer emptySibling />
          <Column />
        </Row>
      </Margin>
      {
        !!isHealthProfessional && (
          <>
            <Margin mx-0>
              <Row>
                <Column>
                  <GreyLabel>{t('collegiate.number')}</GreyLabel>
                  <Span>{user.collegiateNumber}</Span>
                </Column>
                <Spacer emptySibling />
                <Column />
              </Row>
            </Margin>
            <Margin mx-0>
              <Row>
                <Column>
                  <GreyLabel>{t('specialty')}</GreyLabel>
                  <Span>{t(user.specialty.toLowerCase())}</Span>
                </Column>
                <Spacer emptySibling />
                <Column>
                  <GreyLabel>{t('subspecialty')}</GreyLabel>
                  <Span>{user.subspecialty}</Span>
                </Column>
              </Row>
            </Margin>
          </>
        )
      }
      { user.profilePhoto
        && (
        <Margin mx-0>
          <Row>
            <Column>
              <GreyLabel>{t('profile.image')}</GreyLabel>
              <Spacer emptySibling />
              <div>
                <img src={`${user.profilePhoto}?size=150`} alt="profile" />
              </div>
            </Column>
            <Spacer emptySibling />
            <Column />
          </Row>
        </Margin>
        )}
    </div>
  );
};
