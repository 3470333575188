import styled from 'styled-components';

export const TextTransform = styled.span`
  text-transform: ${(props) => ((
    props.transform === 'capitalize'
    || props.transform === 'lowercase'
    || props.transform === 'uppercase'
  )
    ? props.transform
    : 'capitalize'
  )};
`;
