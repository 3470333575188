import styled from 'styled-components';

import { sizes } from '../../../styles/variables';

const Base = styled.h1`
  font-size: ${(props) => props.theme.font.size.xxlarge};
  font-weight: ${(props) => props.theme.font.weight.xbold};
  display: block;
  white-space: nowrap;

  @media screen and (max-width: ${sizes.smMax}) {
    font-size: ${(props) => props.theme.font.size.large};
  }
`;

export const Heading = styled(Base)``;

export const Subheading = styled(Base)`
  font-size: ${(props) => props.theme.font.size.xlarge};;
`;
