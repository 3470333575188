import styled from 'styled-components';

import { sizes } from '../../../styles/variables';
import { margin } from '../../../styles/mixins';

export const Spacer = styled.div`
  margin: ${(props) => (props.customMargin ? props.customMargin : '2%')};

  // @media screen and (max-width: ${sizes.xsMax}) {
  //   margin-top: ${(props) => (props.emptySibling ? '0' : '7%')};
  // }

  ${margin}
`;
