import styled, { css } from 'styled-components';

import { margin as marginMixin } from '../../../styles/mixins';
import { sizes } from '../../../styles/variables';

const Base = css`
  ${(props) => marginMixin(props)}
`;

export const SmallMargin = styled.div`
  margin: 2%;
  ${Base}
`;

export const MediumMargin = styled.div`
  margin: 3%;

  @media screen and (max-width: ${sizes.xsMax}) {
    margin: 6%;
  }

  ${Base}
`;

export const LargeMargin = styled.div`
  margin: 5%;
  ${Base}
`;

export const Margin = styled(MediumMargin)``;
