import React from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import App from '../../components/App';
import { EmptyPage } from '../../components/Common/styled/EmptyPage';
import { FullViewHeader as Header } from '../../components/Common/FullViewHeader';
import { UserProfile } from '../../components/User/UserProfile';
import { isEmpty } from '../../utils/ObjectUtils';
import Loading from '../../components/Common/Loading';
import { TextTransform } from '../../components/Common/styled/TextTransform';
import { GET_USER_FOR_EDITING } from '../../graphql/queries';


export default () => {
  const { t } = useTranslation();
  const { loading, data } = useQuery(GET_USER_FOR_EDITING);

  return (
    <App>
      <EmptyPage>
        <TextTransform>
          <Header title={t('my.profile')} onClose={() => navigate('/')} />
        </TextTransform>
        {
          loading || isEmpty(data)
            ? <Loading />
            : (<UserProfile user={data.user} />)
        }
      </EmptyPage>
    </App>
  );
};
