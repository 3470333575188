import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import IcomoonReact from 'icomoon-react';
import icons from '../../icons/cloud.json';

import { EmptyPageHeader } from './styled/EmptyPage';
import { Heading } from './styled/Heading';

const goBack = () => window.history.back();

export const FullViewHeader = ({ title, onClose = goBack, center, hideCloseButton }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <EmptyPageHeader>
      <Heading className={center ? 'mx-auto' : ''}>{title}</Heading>
      {hideCloseButton ? null : (
        <button onClick={onClose} type="button">
          <IcomoonReact
            iconSet={icons}
            color={themeContext.colors.black}
            size={15}
            icon="close"
          />
        </button>
      )}
    </EmptyPageHeader>
  );
};
